// eslint-disable-next-line
const LOCAL_DEV = {
	FIREBASE_API_KEY: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
	FIREBASE_AUTH_DOMAIN: 'trackit-954bb.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-954bb.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-954bb',
	FIREBASE_STORAGE_BUCKET: 'trackit-954bb.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '799165776336',

	BASE_URL: 'http://localhost:3020',
	API_BASE_URL: 'http://127.0.0.1:5000',
	GOOGLE_MAP_API: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
}

// eslint-disable-next-line
const LOCAL_PROD = {
	FIREBASE_API_KEY: 'AIzaSyC10oErT_m8i0WLcDFlxPmiEwFslLSoU-4',
	FIREBASE_AUTH_DOMAIN: 'trackit-prod-8dd1b.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-prod-8dd1b.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-prod-8dd1b',
	FIREBASE_STORAGE_BUCKET: 'trackit-prod-8dd1b.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '411148091305',

	BASE_URL: 'http://localhost:3020',
	API_BASE_URL: 'http://127.0.0.1:5000',
	GOOGLE_MAP_API: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
}

const DEV = {
	FIREBASE_API_KEY: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
	FIREBASE_AUTH_DOMAIN: 'trackit-954bb.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-954bb.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-954bb',
	FIREBASE_STORAGE_BUCKET: 'trackit-954bb.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '799165776336',

	BASE_URL: 'https://d1gma93xo1rsqh.cloudfront.net',
	API_BASE_URL: 'https://xgng24cr5l.execute-api.us-east-1.amazonaws.com/dev',
	GOOGLE_MAP_API: 'AIzaSyC6IIUG3BEkwi4wzFOnsLR3Fz7hIZkZgU0',
}

const PROD = {
	FIREBASE_API_KEY: 'AIzaSyC10oErT_m8i0WLcDFlxPmiEwFslLSoU-4',
	FIREBASE_AUTH_DOMAIN: 'trackit-prod-8dd1b.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://trackit-prod-8dd1b.firebaseio.com',
	FIREBASE_PROJECT_ID: 'trackit-prod-8dd1b',
	FIREBASE_STORAGE_BUCKET: 'trackit-prod-8dd1b.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '411148091305',

	BASE_URL: 'https://www.trackittechnology.com/',
	API_BASE_URL: 'https://tsk24c4mk8.execute-api.us-east-1.amazonaws.com/prod',
	GOOGLE_MAP_API: 'AIzaSyC10oErT_m8i0WLcDFlxPmiEwFslLSoU-4',
}

function determine_dev() {
	return (
		window.location.origin.includes('d1gma93xo1rsqh.cloudfront.net') ||
		window.location.origin.includes('staging.trackittechnology.com') ||
		process.env.NODE_ENV === 'development'
	)
}

export default {
	dev: determine_dev(),
	apiDebugging: determine_dev(),
	isLocal: window.location.origin.includes('localhost'),

	VERSION: '2.1.5',

	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	// ...LOCAL_DEV,
	// ...LOCAL_PROD,
	// ...DEV
	// ...PROD,
	...(determine_dev() ? DEV : PROD),
}
