import React, { createContext, useState, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import { TEPopupContext } from 'react-tec'

import { docToDataObject } from 'helpers'
import settings from 'configs/settings'

export const LoadingContext = createContext()

export const LoadingProvider = (props) => {
	const data = useAppLoading()
	return <LoadingContext.Provider value={data}>{props.children}</LoadingContext.Provider>
}

//
// 1) Initialize Firebase
// 2) Start Watching Authentication
// 3) Start Loading App Version
//

const useAppLoading = () => {
	const { dispatch } = useContext(TEPopupContext)
	const [user, setUser] = useState()
	const [userLoaded, setUserLoaded] = useState(false)
	const [organization, setOrganization] = useState({})
	const [orgLoaded, setOrgLoaded] = useState(false)
	const [appVersions, setAppVersions] = useState()
	const [appVersionsLoaded, setAppVersionsLoaded] = useState(false)
	const [userActive] = useState(true)

	useEffect(() => {
		const {
			FIREBASE_API_KEY,
			FIREBASE_AUTH_DOMAIN,
			FIREBASE_DATABASE_URL,
			FIREBASE_PROJECT_ID,
			FIREBASE_STORAGE_BUCKET,
			FIREBASE_MESSAGING_SENDER_ID,
		} = settings

		firebase.initializeApp({
			apiKey: FIREBASE_API_KEY,
			authDomain: FIREBASE_AUTH_DOMAIN,
			databaseURL: FIREBASE_DATABASE_URL,
			projectId: FIREBASE_PROJECT_ID,
			storageBucket: FIREBASE_STORAGE_BUCKET,
			messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
		})

		firebase.firestore().settings({
			cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
		})

		firebase
			.firestore()
			.enablePersistence({ synchronizeTabs: true })
			.catch((err) => {
				if (err.code === 'failed-precondition') {
					console.warn(
						'Multiple tabs open, persistence can only be enabled in one tab at a a time.',
					)
				} else if (err.code === 'unimplemented') {
					console.warn(
						'The current browser does not support all of the features required to enable persistence',
					)
				}
			})

		//Authentication Watcher
		let userWatcher
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					const { uid } = user
					userWatcher = firebase
						.firestore()
						.doc(`Coordinator/${uid}`)
						.onSnapshot(
							(snapshot) => {
								const user = docToDataObject(snapshot)
								if (!user) {
									// this block of code is screwing up account creation

									// firebase.auth().signOut()
									// dispatch({
									// 	type: 'show_alert',
									// 	payload: {
									// 		alertTitle: 'Incorrect User Permissions',
									// 		alertMessage:
									// 			'At this time we do not support this type of user on the web portal.',
									// 	},
									// })
									// setUser()
									// setUserLoaded(true)
									return
								}
								if (!user.active) {
									firebase.auth().signOut()
									dispatch({
										type: 'show_alert',
										payload: {
											alertTitle: 'Account Deactivated',
											alertMessage:
												'This account is no longer active. If you believe this account was deactivated in error please contact support@trackit.com',
										},
									})
									setUser()
									setUserLoaded(true)
									return
								}
								setUser(user)
								setUserLoaded(true)
							},
							(e) => {
								console.error(e)
							},
						)
				} else {
					if (userWatcher) {
						userWatcher()
					}
					setUser()
					setUserLoaded(false)
				}
			},
			(e) => console.error(e),
			() => console.log('onAuthStateChanged Completed'),
		)

		return () => {
			if (userWatcher) {
				userWatcher()
			}
			setUser()
			setUserLoaded(false)
			setAppVersions()
		}
	}, [dispatch])

	//App Version
	useEffect(() => {
		const versionsWatcher = firebase
			.firestore()
			.collection('Versions')
			.doc('web')
			.onSnapshot(
				(snapshot) => {
					setAppVersions(docToDataObject(snapshot))
					setAppVersionsLoaded(true)
				},
				(e) => {
					console.error(e)
					setAppVersions(undefined)
					setAppVersionsLoaded(true)
				},
			)

		return () => {
			versionsWatcher()
			setAppVersions(undefined)
			setAppVersionsLoaded(false)
		}
	}, [])

	// Organization
	useEffect(() => {
		if (!user?.org_id) return
		const orgWatcher = firebase
			.firestore()
			.doc(`Organization/${user.org_id}`)
			.onSnapshot(
				(snapshot) => {
					setOrganization(docToDataObject(snapshot))
					setOrgLoaded(true)
				},
				(e) => {
					console.error(e)
					setOrgLoaded(true)
				},
			)
		return () => {
			orgWatcher()
			setOrganization()
			setOrgLoaded(false)
		}
	}, [user])

	return {
		loadingComplete: userLoaded && orgLoaded,
		user,
		userLoaded,
		organization,
		orgLoaded,
		userActive,
		appVersions,
		appVersionsLoaded,
		dispatch,
	}
}
